import { throttle } from 'ui-utilities';

export default class {
    constructor({
        id,
        url,
    }) {
        const el = document.getElementById(id);

        let videoCont;
        let video;

		const resize = throttle(() => {
            const elmWidth = el.offsetWidth;
            const elmHeight = el.offsetHeight;
            const elmRatio = elmWidth / elmHeight;

            let vidWidth = 1920;
            let vidHeight = 1080;
            if (video) {
                vidWidth = video.videoWidth;
                vidHeight = video.videoHeight;
            }

            const vidRatio = vidWidth / vidHeight;

            let newWidth = 0;
            let newHeight = 0;
            if (elmRatio > vidRatio) {
                newWidth = elmWidth;
                newHeight = (elmWidth / vidWidth) * vidHeight;
            } else {
                newWidth = (elmHeight / vidHeight) * vidWidth;
                newHeight = elmHeight;
            }

            videoCont.style.width = newWidth + 'px';
            videoCont.style.height = newHeight + 'px';
		}, 250);

        if (url) {
            videoCont = document.createElement('div');
            el.appendChild(videoCont);

            if (url.indexOf('.mp4') > 0) {
                video = document.createElement('video');
                video.autoplay = true;
                video.controls = false;
                video.loop = true;
                video.muted = true;
                video.preload = 'auto';
                video.setAttribute('playsinline', 'playsinline');
                video.addEventListener('playing', resize);
                video.src = url;
                videoCont.appendChild(video);
            } else {
                videoCont.innerHTML = `<iframe src="${url.split('?')[0]}?title=0&byline=0&portrait=0&autoplay=1&muted=1&controls=0&transparent=1&playsinline=1&background=1" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen="" allow="autoplay"></iframe>`;
            }

            window.addEventListener('orientationchange', resize, {capture: true, passive: true});
            window.addEventListener('resize', resize, {capture: true, passive: true});
            resize();
        }
    }
}
