export default class {
    constructor({
        id,
        pickerHandle,
        name,
        email,
        orderNumber,
        url,
    }) {
        const el = document.getElementById(id);
        const picker = el.querySelector(pickerHandle);
        const script = document.createElement('script');

        function handleCalendlyScriptLoad() {
            // https://help.calendly.com/hc/en-us/articles/360020052833-Advanced-embed-options
            // Tack on order number and installer ID for scheduling appointment in controller action
            Calendly.initInlineWidget({ // eslint-disable-line no-undef
                url,
                parentElement: picker,
                prefill: {
                    name,
                    email,
                    customAnswers: {
                        a1: orderNumber,
                    },
                },
            });
        }

        script.addEventListener('load', handleCalendlyScriptLoad);

        // Add Calendly class to the global namespace
        script.src = 'https://assets.calendly.com/assets/external/widget.js';
        document.head.appendChild(script);
    }
}
