import { router } from 'ui-utilities';

export default class {
    constructor({
        id,
        loadingHandle,
        ordersHandle,
        calendarHandle,
        installerId,
        month,
        year,
        ordersByDay,
        actions,
        events,
        refresh,
    }) {
        const el = document.getElementById(id);
        const loading = el.querySelector(loadingHandle);
        const orders = el.querySelector(ordersHandle);
        const calendar = el.querySelector(calendarHandle);
        const [prev, next] = el.querySelectorAll('nav > form');

        // Keep track of month, year and corresponding orders by day
        month = parseInt(month, 10);
        year = parseInt(year, 10);

        function populateOrdersByDay(day = null) {
            const markup = day
                ? ordersByDay[`_${day}`]
                : Object.values(ordersByDay)[0] || '<p>No orders for this month.</p>';

            orders.innerHTML = markup;
            refresh(orders);
        }
        function getCalendar(mo, yr) {
            const { protocol, hostname } = window.location;
            const url = `${protocol}//${hostname}/shared/orders-calendar`;
            const query = { installerId, month: mo, year: yr };

            function cb(res) {
                const { ordersByDay: o, calendar: c } = JSON.parse(res);

                // Update local state variables
                ordersByDay = o;

                calendar.innerHTML = c;
                refresh(calendar);
                populateOrdersByDay();
                loading.style.display = 'none';
            }

            loading.style.display = 'block';
            router.get({ url, query, cb });
        }

        function handleSetCalendarDay(e) {
            const { day } = e.detail;

            populateOrdersByDay(day);
        }
        function handlePrev(e) {
            e.preventDefault();

            const lastMonth = month - 1;

            year = lastMonth < 1 ? year - 1 : year;
            month = lastMonth < 1 ? 12 : lastMonth;

            getCalendar(month, year);
        }
        function handleNext(e) {
            e.preventDefault();

            const nextMonth = month + 1;

            year = nextMonth > 12 ? year + 1 : year;
            month = nextMonth > 12 ? 1 : nextMonth;

            getCalendar(month, year);
        }

        events.on(actions.setCalendarDay, handleSetCalendarDay);
        prev.addEventListener('submit', handlePrev);
        next.addEventListener('submit', handleNext);

        populateOrdersByDay();
    }
}
