import { router } from 'ui-utilities';

export default class {
    constructor({
        id,
        progressHandle,
        productHandle,
        nextContHandle,
        prevFormHandle,
		finePrintHandle,
        inProcessClass,
        step: s0,
        actions,
        events,
        refresh,
    }) {
        const el = document.getElementById(id);
        const progress = el.querySelector(progressHandle);
        const product = el.querySelector(productHandle);
        const projectSaveCont = el.querySelector('#save-form + noscript');
        const nextCont = el.querySelector(nextContHandle);
        const prevForm = el.querySelector(prevFormHandle);
		const finePrint = el.querySelector(finePrintHandle);

        let currentStep = s0;

        function getStep({
			step,
			pushState = false,
			dir = 'right',
		}) {
            const { protocol, hostname } = window.location;
            const url = `${protocol}//${hostname}/shared/order-builder`;
            const query = { step };

            function cb(res) {
				const {
					markup,
					progressSteps,
					progress: prog,
					product: prod,
					projectSaveForm = null,
					finePrint: fp,
				} = JSON.parse(res);

                // Keep track of current step to send w/ each prev request
                currentStep = step;

                nextCont.setAttribute('data-direction', dir);
                nextCont.innerHTML = markup;
                refresh(nextCont);
                progress.style.transform = `scaleX(${prog})`;
                progress.nextElementSibling.textContent = `${Math.round(prog * 100)}%`;
                progress.nextElementSibling.style.left = `${prog * 100}%`;
                product.innerHTML = prod
                    ? `${`<img src="${prod.image}" />`}${prod.name}`
                    : '';
                if (projectSaveForm !== null) {
                    projectSaveCont.innerHTML = projectSaveForm;
                }
				finePrint.textContent = fp;

                el.classList.toggle(inProcessClass, progressSteps.includes(currentStep));

                if (pushState) {
                    window.history.pushState(
                        { progress: prog, product: prod, step },
                        document.title,
                        `?step=${step}`,
                    );

                    if (window.dataLayer) {
                        window.dataLayer.push({
                            event: 'virtualPageView',
                            virtualPagePath: window.location.pathname + window.location.search,
                            virtualPageTitle: `Shop - ${step}`,
                        });
                    }
                }
            }

            router.get({ url, query, cb });
        }

        function handlePopState({ state }) {
            getStep(state);
        }
        function handleGetNextStep({ detail }) {
            const { nextStep: step } = detail;

            getStep({ step, pushState: true });
        }
        function handlePrevForm(e) {
            e.preventDefault();

            const formData = new FormData(prevForm);

            // Use current step maintained in local state
            formData.set('step', currentStep);

            function cb(res) {
				const { prevStep: step } = JSON.parse(res);

                getStep({ step, pushState: true, dir: 'left' });
            }

            router.post({ formData, cb });
        }

        window.addEventListener('popstate', handlePopState);
        events.on(actions.getNextStep, handleGetNextStep);
        prevForm.addEventListener('submit', handlePrevForm);

        // Initialize current state
        window.history.replaceState({
            ...window.history.state,
            step: s0,
        }, null);
    }
}
