export default function ({ actions, events }) {
    const modalTriggers = document.querySelectorAll('input[type="checkbox"][data-modal-trigger]:not([data-ready="true"])');

    function handleModalTrigger({ currentTarget: trigger }) {
        const markup = trigger.nextElementSibling.textContent
                       || trigger.nextElementSibling.innerHTML;
        const name = trigger.getAttribute('data-modal-name');
        const size = trigger.getAttribute('data-modal-size');
        // Set input checked to false to load markup in modal instead of container
        trigger.checked = false;
        // Load initial markup inside of modal for enhancement
        events.emit(actions.loadModal, { markup, name, size });
    }

    modalTriggers.forEach(mt => {
        mt.setAttribute('data-ready', true);
        mt.addEventListener('change', handleModalTrigger);
    });
}
