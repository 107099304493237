import imageCompression from 'browser-image-compression';

const ALLOWED_TYPES = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'];

export default class {
    constructor({
        id,
        inputHandle,
        capturedClass,
        actions,
        events,
    }) {
        const el = document.getElementById(id);
        const input = el.querySelector(inputHandle);
        const error = el.querySelector('p');

        function handleSetInputError(e) {
            const { name, error: errorText } = e.detail;

            if (input.name !== name) {
                return;
            }

            error.textContent = errorText;
        }
        function handleUploadInputChange({ target }) {
            const label = target.nextElementSibling;
            const preview = label.querySelector('img');
            const file = target.files[0];

			error.innerText = '';

            // Check file type
            if (!ALLOWED_TYPES.includes(file.type)) {
                error.innerText = 'Please upload an image file';

                return;
            }

            // Compress image file
            imageCompression(file, {
                maxSizeMB: 2,
                maxWidthOrHeight: 1000,
            }).then(compressedFile => {
                imageCompression
                    .getDataUrlFromFile(compressedFile)
                    .then(url => {
                        // Show user we captured the file
                        label.classList.add(capturedClass);
                        preview.src = url;

                        // Attach blob to new hidden input w/ same name
                        input.insertAdjacentHTML('afterend', `
                            <input type="hidden" name="${input.name}_base64[name]" value="${file.name}" />
                            <input type="hidden" name="${input.name}_base64[data]" value="${url}" />
                        `);
                    });
            }).catch(({ message }) => { console.log(message); }); // eslint-disable-line no-console
        }
        function handleInvalid() {
            error.innerText = 'Please upload a valid image';
        }

        events.on(actions.setInputError, handleSetInputError);
        input.addEventListener('change', handleUploadInputChange);
        input.addEventListener('invalid', handleInvalid);
    }
}
