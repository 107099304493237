import { router, scroll } from 'ui-utilities';

export default class {
	constructor({
		id,
		actions,
		events,
	}) {
		const el = document.getElementById(id);
		const form = el.querySelector('form');
		const submit = form.querySelector('[type="submit"]');

		function handleSubmit(e) {
			e.preventDefault();

			const formData = new FormData(form);

			submit.setAttribute('disabled', true);

			function cb(res) {
				const {
					success,
					lockLocation = false,
					errors,
					redirect = '',
					nextStep,
				} = JSON.parse(res);

				submit.removeAttribute('disabled');

				if (!success) {
					// Merge formData w/ errors from controller to update error
					// text for all inputs from form, sent to controller.
					const names = [
						...Array.from(formData.keys()),
						...Object.keys(errors),
					];

					names.forEach(name => {
						const error = errors[name] || '';

						events.emit(actions.setInputError, { name, error });
					});

					return;
				}

				if (redirect !== '') {
					window.location = redirect;

					return;
				}

				if (lockLocation) {
					events.emit(actions.lockLocation,);
				}

				events.emit(actions.getNextStep, { nextStep });
			}

			scroll.top(document.body);
			router.post({ formData, cb });
		}

		form.addEventListener('submit', handleSubmit);
	}
}
